<template>
    <base-dialog width="630" :is-open.sync="dialog">
        <v-card-title style="background-color: #f1eeee">
            <span style="color: #78756a" class="font-weight-bold"
                >View Session
            </span>
            <!--            <v-btn-->
            <!--                class="border-radius-25 px-5 ml-5"-->
            <!--                depressed-->
            <!--                rounded-->
            <!--                dark-->
            <!--                color="#389877"-->
            <!--                >Update</v-btn-->
            <!--            >-->
        </v-card-title>
        <v-card-text class="black--text">
            <v-row class="px-5 my-2">
                <v-col cols="12">
                    <v-row>
                        <v-col cols="4" class="font-weight-bold success--text">
                            SESSION TITLE
                        </v-col>
                        <v-col cols="8" class="font-size-16">
                            {{ data.title }}
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12">
                    <v-row>
                        <v-col cols="4" class="font-weight-bold success--text">
                            SESSION DATE
                        </v-col>
                        <v-col cols="8" class="font-size-16">
                            {{ formatDate() }}
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12">
                    <v-row>
                        <v-col cols="4" class="font-weight-bold success--text">
                            GROUP NAME
                        </v-col>
                        <v-col cols="8" class="font-size-16">
                            {{ data.group.name }}
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12">
                    <v-row>
                        <!-- <v-col cols="4" class="font-weight-bold success--text">
                            INVITE LINK
                        </v-col>
                        <v-col cols="8" class="font-size-16">
                            {{ data.session_url }}
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="
                                            copyToClipboard(data.session_url)
                                        "
                                        icon
                                        ><v-icon small color="#369776"
                                            >mdi-content-copy</v-icon
                                        ></v-btn
                                    >
                                </template>
                                <span>Copy invite link</span>
                            </v-tooltip>
                        </v-col> -->
                    </v-row>
                </v-col>
                <v-col cols="12" class="mt-n2">
                    <span class="font-weight-bold success--text">MEMBERS</span>
                    <v-row class="py-2 pl-3">
                        <v-col cols="6">
                            <span
                                class="font-weight-bold secondary--text caption"
                                >CONFIRMED ({{
                                    data.members.confirmed.length
                                }})</span
                            >
                            <div class="mb-2"></div>
                            <ul class="font-size-16">
                                <template
                                    v-for="(user, index) in data.members
                                        .confirmed"
                                >
                                    <li :key="index">
                                        {{ formatFullName(user) }}
                                    </li>
                                </template>
                            </ul>
                        </v-col>
                        <v-col cols="6">
                            <span
                                class="font-weight-bold secondary--text caption"
                                >DECLINED/NO RESPONSE ({{
                                    data.members.declined.length
                                }})</span
                            >
                            <div class="mb-2"></div>
                            <ul class="font-size-16">
                                <template
                                    v-for="(user, index) in data.members
                                        .declined"
                                >
                                    <li :key="index">
                                        {{ formatFullName(user) }}
                                    </li>
                                </template>
                            </ul>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12" class="mt-3">
                    <v-row>
                        <template
                            v-for="(material, index) in data.session_materials"
                        >
                            <v-col cols="12" :key="index">
                                <span class="font-weight-bold success--text"
                                    >SERMON</span
                                >
                                <v-row class="pt-2 pl-3">
                                    <v-col
                                        cols="4"
                                        class="font-weight-bold secondary--text caption"
                                        >TITLE</v-col
                                    >
                                    <v-col cols="8" class="font-size-16">{{
                                        material.attachment.name
                                    }}</v-col>
                                    <v-col
                                        cols="4"
                                        class="font-weight-bold secondary--text caption"
                                        >ATTACHMENT</v-col
                                    >
                                    <v-col cols="8" class="font-size-16">
                                        <a
                                            :href="material.attachment.file"
                                            target="_blank"
                                            class="text-decoration-underline black--text"
                                        >
                                            {{ material.attachment.file }}
                                        </a>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </template>
                    </v-row>
                </v-col>

                <v-col cols="12" v-if="data.session_report">
                    <v-row>
                        <v-col cols="4" class="font-weight-bold success--text">
                            SESSION REPORT
                        </v-col>
                        <v-col cols="8" class="font-size-16">
                            {{ data.session_report }}
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-text>
    </base-dialog>
</template>

<script>
import helperUtility from "@/mixins/helper";
import BaseDialog from "@/components/Base/BaseDialog";
import dateUtility from "@/mixins/date";

export default {
    name: "engage-session-information-dialog",

    components: { BaseDialog },

    props: {
        show: { type: Boolean, default: false, required: true },
        data: { type: [Object, null], required: false },
    },

    data() {
        return {
            dialog: this.show,
        };
    },

    watch: {
        show(value) {
            this.dialog = value;
        },

        data(value) {
            console.log(value);
        },

        dialog(value) {
            if (!value) {
                this.$emit("close");
            }
        },
    },

    methods: {
        copyToClipboard(text) {
            helperUtility.copyText(text);
            this.$notify({
                group: "main-notifications",
                type: "success",
                title: "Copied to Clipboard",
                text: "Session URL was copied to clipboard.",
            });
        },

        formatDate() {
            return dateUtility.formatTimestampToSchedule(
                dateUtility.toLocalDate(this.data.date)
            );
        },

        formatFullName(user) {
            return helperUtility.formatFullName(user);
        },
    },
};
</script>

<style lang="scss" scoped>
.close-icon {
    transition: 0.3s;
    border-radius: 50px !important;
    margin-top: 20px;
    margin-right: 15px;
    padding: 3px;
    background-color: #2c423d;
    color: white;
}
</style>
